import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { FeatureFlag, FeatureFlagName } from '../../@types/FeatureFlag';
import {
  fetchFFAgentProfileEnabled,
  fetchFFAgentPortalClosings,
} from './actions';

interface FeatureFlagsState {
  loading: boolean;
  success: boolean;
  errorMessage: string | undefined;
  featureFlags: FeatureFlag[];
}

const initialState: FeatureFlagsState = {
  success: false,
  loading: false,
  errorMessage: '',
  featureFlags: [],
};

const featureFlags = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFFAgentProfileEnabled.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = true;

        const index = state.featureFlags.findIndex(
          (el) => el.name === 'agent_profile_enabled',
        );
        if (index > -1) {
          state.featureFlags[index] = payload;
        } else {
          state.featureFlags.push(payload);
        }

        state.errorMessage = undefined;
      },
    );
    builder.addCase(
      fetchFFAgentPortalClosings.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = true;

        const index = state.featureFlags.findIndex(
          (el) => el.name === FeatureFlagName.agent_portal_closings,
        );
        if (index > -1) {
          state.featureFlags[index] = payload;
        } else {
          state.featureFlags.push(payload);
        }

        state.errorMessage = undefined;
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchFFAgentProfileEnabled.pending,
        fetchFFAgentPortalClosings.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchFFAgentProfileEnabled.rejected,
        fetchFFAgentPortalClosings.rejected,
      ),
      (state, { payload }) => ({
        ...state,
        loading: false,
        success: false,
        errorMessage: payload,
      }),
    );
  },
});

export default featureFlags.reducer;
