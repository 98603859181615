/* eslint-disable no-shadow */

import { DealType } from '../../../Enums/Deal';

/* eslint-disable no-use-before-define */

/**
 * @public
 */
export enum Step {
  NewReferral = 0,
  NoStatusChange = 1,
  ReachedOut = 2,
  WeSpoke = 3,
  ScheduledMeeting = 4,
  ChangedMeeting = 5,
  MetInPerson = 6,
  SignedAgreement = 7,
  ListedHome = 8,
  UnderContract = 9,
  ChangedCloseDate = 10,
  OutOfContract = 11,
  Closed = 12,
  Released = 13,
  DeListedTemporarily = 14,
  ThankYou = 15,
  PaymentInstructions = 16,
  CashOfferStatus = 17,
  CashOffers = 18,
  DisplayOptions = 19,
  Close = 20,
  NewTransaction = 21,
}

export enum InactiveReason {
  ClaimedByBrokerage = 'Someone from your brokerage claimed this deal',
  Rejected = 'You already rejected this deal',
  AlreadyClaimed = 'This deal has been claimed by other agents',
  Expired = 'This invitation has expired',
}

export interface FieldProps {
  dealType: DealType;
  connection: Connection;
}

export enum FieldTypes {
  alert = 'alert',
  date = 'date',
  dateTime = 'dateTime',
  header = 'header',
  number = 'number',
  paragraph = 'paragraph',
  radio = 'radio',
  select = 'select',
  text = 'text',
  textarea = 'textarea',
  changeState = 'changeState',
  repeaterField = 'repeaterField',
  combinedField = 'combinedField',
  linkOut = 'linkOut',
}

export const sellerOnHoldReasonOptions = [
  'Waiting for listing agreement to expire',
  'Waiting for a different season',
  'Waiting for legal reasons',
  'Working on repairs',
  'Trying FSBO first',
  'Customer traveling / unavailable',
  'Other',
];

export const buyerOnHoldReasonOptions = [
  'Waiting for preapproval',
  'Waiting for a different season',
  'Waiting for legal reasons',
  'Waiting to sell first',
  'Customer traveling / unavailable',
  'Other',
];

export const declinedClosingsOptions = [
  'Customer chose another provider',
  'Broker supplied in-house provider',
  'Other party chose (market norm)',
];
